import { Fragment } from "react";
import vish1 from "../assets/vish1.jpeg";
import FloatingActionButtons from "./FloatingActionButtons";

export default function TopHeading() {
  return (
    <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          role="img"
          aria-labelledby="svg-workcation"
        >
          <title id="svg-workcation">timeline</title>

          <defs>
            <pattern
              id="ad119f34-7694-4c31-947f-5c9d249b21f3"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)"
          />
        </svg>
        <div className="relative">
          <p className=" max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-indigo-600">
            timeline
          </p>

          <blockquote className="mt-10">
            <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
              <p>
                A simple log of all my explorations, mostly engineering and at
                times environment. My{" "}
                <a
                  className="underline"
                  href="https://www.linkedin.com/in/vishm/"
                >
                  &nbsp;linkedin
                </a>{" "}
                profile is mostly up to date and some of my old writings are on{" "}
                <a className="underline" href="https://medium.com/@IAMVISH">
                  Medium.
                </a>
                &nbsp;Some useful AWS resources are available on my&nbsp;
                <a className="underline" href="https://github.com/TheRealVish">
                  &nbsp;github
                </a>
                .
              </p>
            </div>

            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="md:flex-shrink-0">
                  <img
                    className="mx-auto h-20 w-20 rounded-full"
                    src={vish1}
                    alt=""
                  />
                </div>
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">
                    Vish
                  </div>

                  <svg
                    className="hidden md:block mx-1 h-5 w-5 text-indigo-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">
                    Goldsmiths consulting
                  </div>
                </div>
              </div>
            </footer>
            <blockquote className="mt-10">
              <div className="max-w-3xl mx-auto text-center text-xl leading-9 italic font-medium text-gray-600">
                <p>"Mathforgood" is scheduled to be beta live in Jan 2025</p>
                <p>Note: The anonymous messaging app has now been decommissioned - <a className="text-indigo-600" href="https://fadeapp.net">fade</a></p>
              </div>
            </blockquote>

            {/* <div className="relative mt-8">
              <button
                type="submit"
                className="mx-auto text-center flex place-content-center md:place-content-center  md:flex-shrink-0  md:items-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-gray-500"
                onClick={e => {
                  e.preventDefault();
                  window.location.href =
                    "https://commerce.coinbase.com/checkout/edc68f50-8f43-4ec6-9db2-f07de0606d43";
                }}
              >
                Buy me a Crypto Coffee{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-coffee"
                  width="40"
                  height="30"
                  viewBox="0 2 20 24"
                  stroke-width="2"
                  stroke="#ffffff"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 14c.83 .642 2.077 1.017 3.5 1c1.423 .017 2.67 -.358 3.5 -1c.83 -.642 2.077 -1.017 3.5 -1c1.423 -.017 2.67 .358 3.5 1" />
                  <path d="M8 3a2.4 2.4 0 0 0 -1 2a2.4 2.4 0 0 0 1 2" />
                  <path d="M12 3a2.4 2.4 0 0 0 -1 2a2.4 2.4 0 0 0 1 2" />
                  <path d="M3 10h14v5a6 6 0 0 1 -6 6h-2a6 6 0 0 1 -6 -6v-5z" />
                  <path d="M16.746 16.726a3 3 0 1 0 .252 -5.555" />
                </svg>
              </button>
            </div> */}
          </blockquote>
        </div>
      </div>
    </section>
  );
}
